import { ReactComponent as F1Logo } from "../../img/f1_logo.svg";
import { ReactComponent as YoshiLogo } from "../../img/beeg_yoshi.svg";

import "./LogoHeader.css";

function LogoHeader() {
  return (
    <div className="logo-header-container">
      <div className="logo-header-logo-img-container">
        <YoshiLogo />
      </div>
      <div className="logo-header-title-container">
        <div className="logo-header-subtitle-container">
          <h4 className="logo-header-subtitle-text">THE BEEG YOSHI</h4>
        </div>
        <div className="logo-header-title-container">
          <h2 className="logo-header-title-text">
            2024 PREMIER LEAGUE PREDICTIONS
          </h2>
        </div>
      </div>
    </div>
  );
}

export default LogoHeader;
