import arsenal from "../../../img/epl-logos/arsenal.png";
import aston from "../../../img/epl-logos/astonvilla.png";
import bournemouth from "../../../img/epl-logos/bournemouth.png";
import brentford from "../../../img/epl-logos/brentford.png";
import brighton from "../../../img/epl-logos/brighton.png";
import chelsea from "../../../img/epl-logos/chelsea.png";
import crystal from "../../../img/epl-logos/crystalpalace.png";
import everton from "../../../img/epl-logos/everton.png";
import fulham from "../../../img/epl-logos/fulham.png";
import ipswhich from "../../../img/epl-logos/ipswhich.png";
import leicester from "../../../img/epl-logos/leicestercity.png";
import liverpool from "../../../img/epl-logos/liverpool.png";
import mancity from "../../../img/epl-logos/mancity.png";
import manu from "../../../img/epl-logos/manu.png";
import newcastle from "../../../img/epl-logos/newcastle.png";
import nottingham from "../../../img/epl-logos/nottinghamforest.png";
import southampton from "../../../img/epl-logos/southampton.png";
import tottenham from "../../../img/epl-logos/tottenham.png";
import westham from "../../../img/epl-logos/westham.png";
import wolves from "../../../img/epl-logos/wolves.png";

const map = {};

map["arsenal"] = arsenal;
map["aston villa"] = aston;
map["bournemouth"] = bournemouth;
map["brentford"] = brentford;
map["brighton and hove albion"] = brighton;
map["chelsea"] = chelsea;
map["crystal palace"] = crystal;
map["everton"] = everton;
map["fulham"] = fulham;
map["ipswich town"] = ipswhich;
map["leicester city"] = leicester;
map["liverpool"] = liverpool;
map["manchester city"] = mancity;
map["manchester united"] = manu;
map["newcastle united"] = newcastle;
map["nottingham forest"] = nottingham;
map["southampton"] = southampton;
map["tottenham hotspur"] = tottenham;
map["west ham united"] = westham;
map["wolverhampton wanderers"] = wolves;

const logoMap = () => {
  return map;
};

export default logoMap;
